.home-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}
.home-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-video {
  top: 0px;
  left: 0px;
  width: 100%;
  filter: opacity(0.6);
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.home-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.home-burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-black);
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text01 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text02 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text03 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text04 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.home-login {
  margin-right: var(--dl-space-space-twounits);
}
.home-icon-group {
  fill: var(--dl-color-gray-white);
  display: flex;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon4 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-hero-content {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header-container {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading {
  color: rgb(255, 255, 255);
  font-size: 80px;
  max-width: 790px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
}
.home-text06 {
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.home-caption {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 600px;
  line-height: 36px;
}
.home-text09 {
  font-weight: 700;
}
.home-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: flex-start;
}
.home-link {
  color: rgb(0, 0, 0);
  margin-right: var(--dl-space-space-halfunit);
  text-decoration: underline;
  background-color: rgb(255, 255, 255);
}
.home-link1 {
  color: rgb(0, 0, 0);
  text-decoration: underline;
  background-color: rgb(255, 255, 255);
}
.home-note {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: flex-start;
}
.home-caption1 {
  color: rgb(124, 124, 128);
  font-size: 40px;
  max-width: 800px;
  align-self: center;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  margin-right: 0px;
}
.home-text13 {
  font-size: 30px;
}
.home-statistics {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.home-stat {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 1px;
}
.home-header1 {
  color: rgb(255, 255, 255);
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.home-caption2 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.home-stat1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 1px;
}
.home-header2 {
  color: rgb(255, 255, 255);
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.home-caption3 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.home-stat2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-header3 {
  color: rgb(255, 255, 255);
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.home-caption4 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.home-slider {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.home-header4 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading1 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  max-width: 900px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 400;
}
.home-text15 {
  font-weight: 400;
}
.home-text16 {
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 400;
}
.home-text17 {
  font-family: Playfair Display;
  font-weight: 400;
}
.home-text18 {
  font-weight: 400;
}
.home-text19 {
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 400;
}
.home-text20 {
  font-weight: 400;
}
.home-text21 {
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.home-caption5 {
  color: rgb(124, 124, 128);
  font-size: 24px;
  max-width: 600px;
  line-height: 36px;
}
.home-not-public {
  width: 100%;
  display: none;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-header-container1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  z-index: 100;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header5 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading2 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-text25 {
  font-style: italic;
  font-family: Playfair Display;
}
.home-caption6 {
  color: rgb(124, 124, 128);
  font-size: 24px;
  max-width: 600px;
  line-height: 36px;
}
.home-button {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-image {
  top: 0px;
  right: 0px;
  width: 720px;
  bottom: 0px;
  margin: auto;
  position: absolute;
  object-fit: cover;
}
.home-objectives {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-growth {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-content1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header6 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header7 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading3 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-weight: 400;
}
.home-text28 {
  font-style: normal;
}
.home-text29 {
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.home-text30 {
  font-style: normal;
  font-family: Inter;
  font-weight: 400;
}
.home-text31 {
  font-style: normal;
}
.home-caption7 {
  color: rgb(124, 124, 128);
  width: 100%;
  height: 334px;
  font-size: 24px;
  max-width: 560px;
  line-height: 36px;
}
.home-text32 {
  font-style: italic;
}
.home-testimonial {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-information {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-author {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  border-color: #7C7C80;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: center;
  border-right-width: 1px;
}
.home-icon6 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-name {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.home-link2 {
  font-size: 14px;
  text-decoration: underline;
}
.home-text40 {
  font-size: 14px;
}
.home-link3 {
  font-size: 14px;
  text-decoration: underline;
}
.home-container4 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-images {
  flex: 1;
  display: flex;
  position: relative;
  align-items: flex-end;
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-square {
  top: 64px;
  right: -35px;
  width: 180px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  aspect-ratio: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #0000FF;
}
.home-image1 {
  width: 640px;
  z-index: 100;
  margin-top: var(--dl-space-space-sixunits);
  object-fit: cover;
}
.home-text42 {
  color: #8a8888;
  font-size: 12px;
  align-self: flex-end;
  padding-top: var(--dl-space-space-halfunit);
}
@media(max-width: 1200px) {
  .home-container3 {
    justify-content: flex-start;
  }
  .home-link {
    color: rgb(0, 0, 0);
    margin-right: var(--dl-space-space-halfunit);
    text-decoration: inherit;
    background-color: rgb(255, 255, 255);
  }
  .home-text11 {
    color: rgb(0, 0, 0);
    margin-right: var(--dl-space-space-halfunit);
    text-decoration: inherit;
    background-color: rgb(255, 255, 255);
  }
  .home-link1 {
    color: rgb(0, 0, 0);
    text-decoration: inherit;
    background-color: rgb(255, 255, 255);
  }
  .home-caption7 {
    width: 491px;
    height: auto;
  }
  .home-image1 {
    width: 497px;
    height: 345px;
  }
  .home-text42 {
    font-size: 8px;
  }
}
@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .home-hero-content {
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-content {
    padding-top: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-stat {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-stat1 {
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-stat2 {
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-growth {
    flex-direction: column;
  }
  .home-testimonial {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-images {
    padding-top: 0px;
  }
  .home-square {
    top: -20px;
    right: -20px;
  }
  .home-image1 {
    margin-top: 0px;
  }
}
@media(max-width: 767px) {
  .home-burger-menu {
    display: flex;
  }
  .home-heading {
    font-size: 60px;
  }
  .home-note {
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-caption1 {
    font-size: 18px;
    max-width: 90%;
    line-height: 45px;
    margin-right: 0px;
  }
  .home-statistics {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-stat {
    gap: var(--dl-space-space-unit);
  }
  .home-header1 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption2 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-stat1 {
    gap: var(--dl-space-space-unit);
  }
  .home-header2 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption3 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-stat2 {
    gap: var(--dl-space-space-unit);
  }
  .home-header3 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption4 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-slider {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-heading1 {
    font-size: 40px;
  }
  .home-caption5 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-not-public {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-heading2 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption6 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-image {
    width: 300px;
  }
  .home-objectives {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-growth {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-content1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-header6 {
    gap: var(--dl-space-space-unit);
  }
  .home-heading3 {
    font-size: 40px;
  }
  .home-caption7 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text32 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text33 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text34 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text35 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-icon6 {
    width: 40px;
    height: 40px;
  }
  .home-name {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text36 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-image1 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .home-hero {
    width: auto;
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-hero-content {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
  }
  .home-heading {
    font-size: 46px;
    max-width: 100%;
  }
  .home-caption {
    width: auto;
    font-size: 16px;
    max-width: auto;
    min-width: auto;
    line-height: 24px;
  }
  .home-container3 {
    justify-content: flex-start;
  }
  .home-link {
    width: 148px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: 20px;
  }
  .home-text11 {
    width: 148px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: 20px;
  }
  .home-link1 {
    color: rgb(0, 0, 0);
    width: 154px;
    text-align: center;
    padding-right: var(--dl-space-space-twounits);
    background-color: rgb(255, 255, 255);
  }
  .home-caption1 {
    line-height: 1.5;
  }
  .home-heading1 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-image {
    height: 400px;
  }
  .home-heading3 {
    width: auto;
    font-size: 36px;
    min-width: var(--dl-size-size-xsmall);
  }
  .home-caption7 {
    width: auto;
    height: auto;
    max-width: auto;
  }
}
