.copyright-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}
.copyright-note {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: flex-start;
}
.copyright-caption {
  color: rgb(124, 124, 128);
  font-size: 40px;
  max-width: 800px;
  align-self: center;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  margin-right: 0px;
}
.copyright-text02 {
  font-size: 30px;
}
.copyright-text05 {
  font-size: 30px;
}
.copyright-navlink {
  color: #ffffff;
  align-self: center;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-threeunits);
  text-decoration: none;
  background-color: #2f2f2f;
}
.copyright-not-public {
  width: 100%;
  display: none;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.copyright-header-container {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  z-index: 100;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.copyright-header {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.copyright-heading {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.copyright-text12 {
  font-style: italic;
  font-family: Playfair Display;
}
.copyright-caption1 {
  color: rgb(124, 124, 128);
  font-size: 24px;
  max-width: 600px;
  line-height: 36px;
}
.copyright-button {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.copyright-image {
  top: 0px;
  right: 0px;
  width: 720px;
  bottom: 0px;
  margin: auto;
  position: absolute;
  object-fit: cover;
}
@media(max-width: 1200px) {
  .copyright-navlink {
    color: rgb(0, 0, 0);
    margin-right: var(--dl-space-space-halfunit);
    text-decoration: inherit;
    background-color: rgb(255, 255, 255);
  }
  .copyright-text09 {
    color: rgb(0, 0, 0);
    margin-right: var(--dl-space-space-halfunit);
    text-decoration: inherit;
    background-color: rgb(255, 255, 255);
  }
}
@media(max-width: 767px) {
  .copyright-note {
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .copyright-caption {
    font-size: 18px;
    max-width: 90%;
    line-height: 45px;
    margin-right: 0px;
  }
  .copyright-not-public {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .copyright-heading {
    font-size: 36px;
    line-height: 42px;
  }
  .copyright-caption1 {
    font-size: 16px;
    line-height: 24px;
  }
  .copyright-image {
    width: 300px;
  }
}
@media(max-width: 479px) {
  .copyright-caption {
    line-height: 1.5;
  }
  .copyright-navlink {
    width: 148px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: 20px;
  }
  .copyright-text09 {
    width: 148px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: 20px;
  }
  .copyright-image {
    height: 400px;
  }
}
