.footer-footer {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.footer-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.footer-logo {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.footer-text {
  color: rgb(124, 124, 128);
  width: 400px;
  font-size: 16px;
  line-height: 24px;
  padding-top: var(--dl-space-space-halfunit);
}
.footer-link {
  text-decoration: underline;
}
.footer-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-container2 {
  display: flex;
  align-items: flex-start;
  margin-right: 10rem;
  flex-direction: row;
  justify-content: space-between;
}
.footer-product-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: 10rem;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text3 {
  color: #ffffff;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-text4 {
  color: rgb(255, 255, 255);
  margin-bottom: var(--dl-space-space-unit);
}
.footer-link1 {
  text-decoration: underline;
}
.footer-text6 {
  color: rgb(255, 255, 255);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: underline;
}
.footer-navlink {
  color: rgb(255, 255, 255);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: underline;
}
.footer-company-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text7 {
  color: #ffffff;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-link2 {
  color: rgb(255, 255, 255);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: underline;
}
.footer-link3 {
  color: rgb(255, 255, 255);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: underline;
}
.footer-link4 {
  color: rgb(255, 255, 255);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: underline;
}
.footer-link5 {
  color: rgb(255, 255, 255);
  text-decoration: underline;
}
.footer-separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-900);
}
.footer-link6 {
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: #ffffff;
}
.footer-image {
  width: 21px;
  object-fit: cover;
}
.footer-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.footer-text8 {
  color: #7C7C80;
  font-size: 14px;
  line-height: 21px;
  padding-top: var(--dl-space-space-unit);
}


@media(max-width: 991px) {
  .footer-footer {
    flex-direction: column;
  }
  .footer-container2 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .footer-product-container {
    margin-right: var(--dl-space-space-fourunits);
  }
  .footer-text8 {
    color: rgb(124, 124, 128);
    font-size: 14px;
    line-height: 21px;
  }
}
@media(max-width: 767px) {
  .footer-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .footer-container {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-logo {
    align-items: center;
  }
  .footer-text {
    padding-bottom: var(--dl-space-space-unit);
  }
  .footer-links-container {
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }
  .footer-container2 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .footer-product-container {
    margin-right: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 479px) {
  .footer-footer {
    padding: var(--dl-space-space-unit);
  }
  .footer-container {
    align-items: center;
    flex-direction: column;
  }
  .footer-text {
    width: auto;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-links-container {
    align-items: center;
    flex-direction: column;
  }
  .footer-container2 {
    margin-right: 0px;
  }
  .footer-link6 {
    justify-content: center;
  }
}
